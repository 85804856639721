
function sliderFull(){
	if (!$('.js-app-slider').length) {
		return false;
	}
	let boxSlider = $('.js-app-slider');
	let Slider = $('.app-slider__body', boxSlider);
	Slider.each(function(index, el) {
		$(this).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: $(this).closest(boxSlider).find('.js-slide--prev'),
			nextArrow: $(this).closest(boxSlider).find('.js-slide--next'),
			focusOnSelect: true,
			dots: true,
			infinite: true,
			fade: true,
			autoplay: true,
			autoplaySpeed: 5000,
			// variableWidth: true,
		});
	});
}

/**
 * Инит модуля
 */
function init() {
	sliderFull();
}

module.exports = {
	init,
};
